<template>
  <section id="usual">
    <v-container>
      <v-row>
        <v-col
          cols="12"
          lg="3"
        >
          <h2 class="mb-4">
            Заявка на оформление
          </h2>
        </v-col>
        <v-col
          cols="6"
          lg="1"
          class="px-0"
        >
          <v-autocomplete
            v-model="type"
            auto-select-first
            outlined
            hide-details="auto"
            dense
            background-color="white"
            :items="['ДТ', 'СД']"
            class="mr-1 p-0"
          />
        </v-col>
        <v-col
          cols="6"
          lg="1"
          class="px-0"
        >
          <v-autocomplete
            v-model="code"
            auto-select-first
            outlined
            hide-details="auto"
            dense
            background-color="white"
            :items="codes"
            class="mr-1 p-0"
          />
        </v-col>
        <v-col
          cols="12"
          lg="7"
        >
          <v-alert
            color="#F5F5F5"
            elevation="2"
          >
            <div class="text-body-2 black--text d-flex align-start mb-2">
              <v-icon
                x-small
                color="#000"
              >
                mdi-asterisk
              </v-icon>
              <span>Декларант не сможет сделать ДТ без достаточного количества информации и документов.</span>
            </div>
            <div class="text-body-2 black--text d-flex align-start mb-2">
              <v-icon
                x-small
                color="#000"
              >
                mdi-asterisk
              </v-icon>
              <span>Заполните лишь те данные, в которых Вы уверены.</span>
            </div>
          </v-alert>
        </v-col>
        <v-col
          cols="12"
          lg="12"
        >
          <div class="grey-box">
            <v-row
              justify="space-between"
              align="center"
            >
              <v-col
                cols="10"
                class="text-h6 font-weight-bold"
              >
                Данные о заявителе
              </v-col>
              <v-btn
                min-width="36px"
                max-width="36px"
                plain
                @click="toggleVisibility('user')"
              >
                <v-icon>{{ visibility.user ? 'mdi-chevron-up' :'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-row>

            <v-row v-show="visibility.user">
              <v-col
                cols="12"
                lg="4"
              >
                <label for="">Ф.И.О.</label>
                <v-text-field
                  v-model="userFIO"
                  color="#5CB7B1"
                  background-color="#fff"
                  solo
                  hide-details="auto"
                  class="mb-2"
                  flat
                  placeholder="Иванов Иван Иванович"
                  outlined
                  dense
                  disabled
                />
              </v-col>
              <v-col
                cols="12"
                lg="3"
              >
                <label for="">E-mail</label>
                <v-text-field
                  v-model="userEmail"
                  color="#5CB7B1"
                  background-color="#fff"
                  solo
                  hide-details="auto"
                  class="mb-2"
                  flat
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                lg="3"
              >
                <label for="">Телефон для связи*</label>
                <v-text-field
                  v-model="userPhone"
                  v-mask="'+375#########'"
                  color="#5CB7B1"
                  background-color="#fff"
                  solo
                  hide-details="auto"
                  class="mb-2"
                  flat
                  outlined
                  dense
                  placeholder="+375"
                />
              </v-col>
              <v-col
                cols="12"
                lg="12"
              >
                <label for="">Комментарий исполнителю</label>
                <v-textarea
                  v-model="clientComment"
                  solo
                  flat
                  color="#32a1af"
                  background-color="#fff"
                  hide-details="auto"
                  no-resize
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </div>
        </v-col>


        <v-col
          cols="12"
          lg="6"
        >
          <div class="grey-box">
            <v-row
              justify="space-between"
              align="center"
            >
              <v-col
                cols="10"
                class="text-h6 font-weight-bold"
              >
                Коммерческие документы*
              </v-col>
              <v-btn
                min-width="36px"
                max-width="36px"
                plain
                @click="toggleVisibility('commercialDocs')"
              >
                <v-icon>{{ visibility.commercialDocs ? 'mdi-chevron-up' :'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-row>
            <v-row v-show="visibility.commercialDocs">
              <v-col
                cols="12"
                lg="12"
              >
                <label for="">Приложите документы (договор и тд.)</label>
                <v-file-input
                  v-model="commercialDocs"
                  dense
                  outlined
                  color="#32a1af"
                  solo
                  flat
                  multiple
                  clearable
                  :accept="acceptedFormats"
                  placeholder="Для выбора нескольких файлов удерживайте клавишу Ctrl"
                />
              </v-col>
            </v-row>
          </div>
        </v-col>


        <v-col
          cols="12"
          lg="6"
        >
          <div class="grey-box">
            <v-row
              justify="space-between"
              align="center"
            >
              <v-col
                cols="10"
                class="text-h6 font-weight-bold"
              >
                Необходимость оригинала акта выполн. работ*
              </v-col>
              <v-btn
                min-width="36px"
                max-width="36px"
                plain
                @click="toggleVisibility('originalBill')"
              >
                <v-icon>{{ visibility.originalBill ? 'mdi-chevron-up' :'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-row>
            <v-row v-show="visibility.originalBill ">
              <v-col
                cols="12"
                lg="12"
              >
                <v-radio-group
                  v-model="needOriginalBill"
                  hide-details="true"
                  row
                >
                  <v-radio
                    label="Нет"
                    :value="false"
                    color="#5CB7B1"
                  />
                  <v-radio
                    label="Да"
                    :value="true"
                    color="#5CB7B1"
                    class="mb-0"
                  />
                </v-radio-group>
                <label for="">Укажите адрес для отправки документов</label>
                <v-textarea
                  v-model="billAddress"
                  :disabled="needOriginalBill === false"
                  rows="2"
                  solo
                  flat
                  color="#32a1af"
                  background-color="#fff"
                  hide-details="auto"
                  no-resize
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </div>
        </v-col>


        <v-col
          cols="12"
          :lg="isTypeSD ? '6' : '12'"
        >
          <div class="grey-box">
            <v-row
              justify="space-between"
              align="center"
            >
              <v-col
                cols="10"
                class="text-h6 font-weight-bold"
              >
                Контрагент
              </v-col>
              <v-btn
                min-width="36px"
                max-width="36px"
                plain
                @click="toggleVisibility('counteragent')"
              >
                <v-icon>{{ visibility.counteragent ? 'mdi-chevron-up' :'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-row>

            <v-row v-show="visibility.counteragent">
              <v-col
                cols="12"
                lg=""
              >
                <label for="">Выберите контрагента</label>
                <v-select
                  solo
                  color="#5CB7B1"
                  item-color="#5CB7B1"
                  background-color="white"
                  item-text="name"
                  return-object
                  flat
                  class="mb-2"
                  hide-details="auto"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col
          cols="12"
          lg="6"
        >
          <div class="grey-box">
            <v-row
              justify="space-between"
              align="center"
            >
              <v-col
                cols="10"
                class="text-h6 font-weight-bold"
              >
                Договор
              </v-col>
              <v-btn
                min-width="36px"
                max-width="36px"
                plain
                @click="toggleVisibility('contract')"
              >
                <v-icon>{{ visibility.contract ? 'mdi-chevron-up' :'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-row>

            <v-row v-show="visibility.contract">
              <v-col
                cols="12"
                lg="12"
              >
                <!-- <v-radio-group row>
                  <v-radio
                    label="Разовый"
                    value="Разовый"
                    color="#5CB7B1"
                  />
                  <v-radio
                    label="Долгосрочный"
                    value="Долгосрочный"
                    color="#5CB7B1"
                  />
                  <v-radio
                    label="Нет"
                    value="Нет"
                    color="#5CB7B1"
                    class="mb-0"
                  />
                </v-radio-group> -->
                <label for="">Приложите документы (договор и тд.)</label>
                <v-file-input
                  v-model="contract"
                  dense
                  outlined
                  color="#32a1af"
                  solo
                  flat
                  multiple
                  clearable
                  :accept="acceptedFormats"
                  placeholder="Для выбора нескольких файлов удерживайте клавишу Ctrl"
                />
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col
          cols="12"
          lg="6"
        >
          <div class="grey-box">
            <v-row
              justify="space-between"
              align="center"
            >
              <v-col
                cols="10"
                class="text-h6 font-weight-bold"
              >
                Приложения к договору
              </v-col>
              <v-btn
                min-width="36px"
                max-width="36px"
                plain
                @click="toggleVisibility('contractAdditional')"
              >
                <v-icon>{{ visibility.contractAdditional ? 'mdi-chevron-up' :'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-row>

            <v-row v-show="visibility.contractAdditional ">
              <v-col
                cols="12"
                lg="12"
              >
                <!-- <v-radio-group row>
                  <v-radio
                    label="Есть"
                    value="Есть"
                    color="#5CB7B1"
                  />
                  <v-radio
                    label="Нет"
                    value="Нет"
                    color="#5CB7B1"
                    class="mb-0"
                  />
                </v-radio-group> -->
                <label for="">Приложите документы (доп. соглашение, спецификация и тд.)</label>
                <v-file-input
                  v-model="contractAdditional"
                  dense
                  outlined
                  color="#32a1af"
                  solo
                  flat
                  multiple
                  clearable
                  :accept="acceptedFormats"
                  placeholder="Для выбора нескольких файлов удерживайте клавишу Ctrl"
                />
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col
          v-if="!isTypeSD"
          cols="12"
          lg="6"
        >
          <div class="grey-box">
            <v-row
              justify="space-between"
              align="center"
            >
              <v-col
                cols="10"
                class="text-h6 font-weight-bold"
              >
                Регистрация сделки
              </v-col>
              <v-btn
                min-width="36px"
                max-width="36px"
                plain
                @click="toggleVisibility('contractRegistration')"
              >
                <v-icon>{{ visibility.contractRegistration ? 'mdi-chevron-up' :'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-row>

            <v-row v-show="visibility.contractRegistration">
              <v-col
                cols="12"
                lg="12"
              >
                <v-radio-group row>
                  <v-radio
                    label="Есть"
                    value="Есть"
                    color="#5CB7B1"
                  />
                  <v-radio
                    label="Нет"
                    value="Не требуется"
                    color="#5CB7B1"
                    class="mb-0"
                  />
                </v-radio-group>
                <label for="">Приложите документы </label>
                <v-file-input
                  v-model="contractRegistration"
                  dense
                  outlined
                  color="#32a1af"
                  solo
                  flat
                  multiple
                  placeholder="Для выбора нескольких файлов удерживайте клавишу Ctrl"
                  clearable
                  :accept="acceptedFormats"
                />
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col
          cols="12"
          lg="6"
        >
          <div class="grey-box">
            <v-row
              justify="space-between"
              align="center"
            >
              <v-col
                cols="10"
                class="text-h6 font-weight-bold"
              >
                Условия оплаты
              </v-col>
              <v-btn
                min-width="36px"
                max-width="36px"
                plain
                @click="toggleVisibility('paymentCondition')"
              >
                <v-icon>{{ visibility.paymentCondition ? 'mdi-chevron-up' :'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-row>

            <v-row v-show="visibility.paymentCondition ">
              <v-col
                cols="12"
                lg="12"
              >
                <v-radio-group
                  v-model="prepayment"
                  row
                >
                  <v-radio
                    label="Оплата после поставки"
                    :value="false"
                    color="#5CB7B1"
                  />
                  <v-radio
                    label="Предоплата"
                    :value="true"
                    color="#5CB7B1"
                    class="mb-0"
                  />
                </v-radio-group>
                <label for="">Приложите документы (платежное поручение и др.) </label>
                <v-file-input
                  v-model="paymentCondition"
                  dense
                  outlined
                  color="#32a1af"
                  solo
                  flat
                  multiple
                  placeholder="Для выбора нескольких файлов удерживайте клавишу Ctrl"
                  clearable
                  :accept="acceptedFormats"
                />
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col
          cols="12"
          lg="6"
        >
          <div class="grey-box">
            <v-row
              justify="space-between"
              align="center"
            >
              <v-col
                cols="10"
                class="text-h6 font-weight-bold"
              >
                Счет-фактура
              </v-col>
              <v-btn
                min-width="36px"
                max-width="36px"
                plain
                @click="toggleVisibility('invoice')"
              >
                <v-icon>{{ visibility.invoice ? 'mdi-chevron-up' :'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-row>

            <v-row v-show="visibility.invoice">
              <v-col
                cols="12"
                lg="12"
              >
                <!-- <v-radio-group row>
                  <v-radio
                    label="Счет"
                    value="Счет"
                    color="#5CB7B1"
                  />
                  <v-radio
                    label="Счет ...."
                    value="Счет ...."
                    color="#5CB7B1"
                    class="mb-0"
                  />
                </v-radio-group> -->
                <label for="">Приложите документы (инвойс, счет-фактура и тд.) </label>
                <v-file-input
                  v-model="invoice"
                  dense
                  outlined
                  color="#32a1af"
                  solo
                  flat
                  multiple
                  placeholder="Для выбора нескольких файлов удерживайте клавишу Ctrl"
                  clearable
                  :accept="acceptedFormats"
                />
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col
          v-if="!isTypeSD"
          cols="12"
          lg="6"
        >
          <div class="grey-box">
            <v-row
              justify="space-between"
              align="center"
            >
              <v-col
                cols="10"
                class="text-h6 font-weight-bold"
              >
                Условия поставки
              </v-col>
              <v-btn
                min-width="36px"
                max-width="36px"
                plain
                @click="toggleVisibility('delivery')"
              >
                <v-icon>{{ visibility.delivery ? 'mdi-chevron-up' :'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-row>

            <v-row v-show="visibility.delivery">
              <v-col
                cols="12"
                lg="5"
              >
                <label for="">Инкотернс 2000 </label>
                <v-autocomplete
                  v-model="deliveryTermsCode"
                  auto-select-first
                  :items="deliveryTerms"
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  color="#5CB7B1"
                  item-color="#5CB7B1"
                  item-text="code"
                  item-value="code"
                  return-object
                >
                  <template #item="{ item }">
                    <div style="white-space:nowrap;">
                      {{ item.code }} - {{ item.name }}
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                lg="7"
              >
                <label for="">Географическое расположение нас. пункта</label>
                <v-text-field
                  v-model="deliveryPlace"
                  color="#5CB7B1"
                  background-color="#fff"
                  solo
                  hide-details="auto"
                  flat
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                lg="12"
              >
                <label for="">Вид поставки</label>
                <v-autocomplete
                  v-model="deliveryTypeCode"
                  auto-select-first
                  :items="delivery"
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  color="#5CB7B1"
                  item-color="#5CB7B1"
                  item-text="text"
                  item-value="search"
                  return-object
                />
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col
          v-if="!isTypeSD"
          cols="12"
          lg="6"
        >
          <div class="grey-box">
            <v-row
              justify="space-between"
              align="center"
            >
              <v-col
                cols="10"
                class="text-h6 font-weight-bold"
              >
                Транспорт
              </v-col>
              <v-btn
                min-width="36px"
                max-width="36px"
                plain
                @click="toggleVisibility('transport')"
              >
                <v-icon>{{ visibility.transport ? 'mdi-chevron-up' :'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-row>

            <v-row v-show="visibility.transport">
              <v-col
                cols="12"
                lg="12"
              >
                <label for="">Выберите вид транспорта</label>
                <v-autocomplete
                  v-model="borderTransportTypeCode"
                  auto-select-first
                  :items="transport"
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  color="#5CB7B1"
                  item-color="#5CB7B1"
                  class="pb-2"
                  item-text="text"
                  item-value="search"
                  return-object
                />
                <label
                  for=""
                >Приложите документы (CMR, ж/д накладная, и т.д. ) </label>
                <v-file-input
                  v-model="borderTransport"
                  dense
                  outlined
                  color="#32a1af"
                  solo
                  flat
                  multiple
                  placeholder="Для выбора нескольких файлов удерживайте клавишу Ctrl"
                  clearable
                  :accept="acceptedFormats"
                />
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col
          v-if="!isTypeSD"
          cols="12"
          lg="6"
        >
          <div class="grey-box">
            <v-row
              justify="space-between"
              align="center"
            >
              <v-col
                cols="10"
                class="text-h6 font-weight-bold"
              >
                Упаковка
              </v-col>
              <v-btn
                min-width="36px"
                max-width="36px"
                plain
                @click="toggleVisibility('package')"
              >
                <v-icon>{{ visibility.package ? 'mdi-chevron-up' :'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-row>

            <v-row v-show="visibility.package">
              <v-col
                cols="12"
                lg="12"
              >
                <label for="">Выберите упаковку</label>
                <v-autocomplete
                  v-model="selectedPackage"
                  auto-select-first
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  color="#5CB7B1"
                  item-color="#5CB7B1"
                  :items="packages"
                  item-text="text"
                  item-value="search"
                  class="pb-2"
                  return-object
                />
                <label for="">Приложите упаковочный лист</label>
                <v-file-input
                  v-model="packageType"
                  dense
                  outlined
                  color="#32a1af"
                  solo
                  flat
                  multiple
                  placeholder="Для выбора нескольких файлов удерживайте клавишу Ctrl"
                  clearable
                  :accept="acceptedFormats"
                />
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col
          v-if="!isTypeSD"
          cols="12"
          lg="6"
        >
          <div class="grey-box">
            <v-row
              justify="space-between"
              align="center"
            >
              <v-col
                cols="10"
                class="text-h6 font-weight-bold"
              >
                Страна назначения
              </v-col>
              <v-btn
                min-width="36px"
                max-width="36px"
                plain
                @click="toggleVisibility('destination')"
              >
                <v-icon>{{ visibility.destination ? 'mdi-chevron-up' :'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-row>

            <v-row v-show="visibility.destination">
              <v-col
                cols="12"
                lg="12"
              >
                <label for="">Выберите страну назначения</label>
                <v-autocomplete
                  v-model="selectedCountry"
                  auto-select-first
                  :items="countries"
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  color="#5CB7B1"
                  item-color="#5CB7B1"
                  return-object
                  item-text="text"
                  item-value="search"
                />
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col
          cols="12"
          lg="6"
        >
          <div class="grey-box">
            <v-row
              justify="space-between"
              align="center"
            >
              <v-col
                cols="10"
                class="text-h6 font-weight-bold"
              >
                Характер сделки
              </v-col>
              <v-btn
                min-width="36px"
                max-width="36px"
                plain
                @click="toggleVisibility('deal')"
              >
                <v-icon>{{ visibility.deal ? 'mdi-chevron-up' :'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-row>

            <v-row v-show="visibility.deal">
              <v-col
                cols="12"
                lg="12"
              >
                <label for="">Дополнительное поле к характеру сделки</label>
                <v-autocomplete
                  v-model="dealCharacterCode"
                  auto-select-first
                  :items="characters"
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  color="#5CB7B1"
                  item-color="#5CB7B1"
                  item-text="text"
                  item-value="search"
                  return-object
                />
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col
          cols="12"
          lg="12"
        >
          <div class="grey-box">
            <v-row
              justify="space-between"
              align="center"
            >
              <v-col
                cols="10"
                class="text-h6 font-weight-bold"
              >
                Прочие документы
              </v-col>
              <v-btn
                min-width="36px"
                max-width="36px"
                plain
                @click="toggleVisibility('documents')"
              >
                <v-icon>{{ visibility.documents ? 'mdi-chevron-up' :'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-row>
            <div v-show="visibility.documents">
              <v-row
                v-for="(item, i) in otherDocs"
                :key="i"
              >
                <v-col
                  cols="12"
                  lg="6"
                >
                  <label for="">Приложите документ</label>
                  <v-file-input
                    v-model="item.file"
                    dense
                    outlined
                    color="#32a1af"
                    solo
                    flat
                    multiple
                    :accept="acceptedFormats"
                    clearable
                    placeholder="Для выбора нескольких файлов удерживайте клавишу Ctrl"
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <label for="">Введите название документа</label>
                  <v-text-field
                    v-model="item.fileName"
                    color="#32a1af"
                    background-color="#fff"
                    solo
                    hide-details="auto"
                    class="mb-2"
                    flat
                    outlined
                    dense
                  >
                    <template slot="append-outer">
                      <v-icon
                        v-if="otherDocs.length > 1"
                        @click="deleteDoc(i)"
                      >
                        mdi-delete
                      </v-icon>
                      <v-icon
                        v-if="i == otherDocs.length - 1"
                        class="ml-2"
                        @click="addDoc"
                      >
                        mdi-plus
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>

        <v-col
          cols="12"
          lg="12"
        >
          <div class="grey-box">
            <v-row
              justify="space-between"
              align="center"
            >
              <v-col
                cols="10"
                class="text-h6 font-weight-bold"
              >
                Выбор исполнителя
              </v-col>
              <v-btn
                v-show="false"
                min-width="36px"
                max-width="36px"
                plain
                @click="toggleVisibility('performer')"
              >
                <v-icon>{{ visibility.performer ? 'mdi-chevron-up' :'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-row>
            <v-row v-show="visibility.performer">
              <v-col
                cols="12"
                lg="7"
              >
                <v-row>
                  <v-col
                    cols="12"
                    lg="4"
                  >
                    <label for="">Филиал*</label>
                    <v-select
                      v-model="selectedBranch"
                      solo
                      color="#5CB7B1"
                      item-color="#5CB7B1"
                      background-color="white"
                      item-text="divisionName"
                      item-value="id"
                      flat
                      class="mb-2"
                      hide-details="auto"
                      outlined
                      dense
                      placeholder="Не выбрано"
                      :items="btsDivisions"
                      clearable
                      @change="loadBtsDepartments"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    lg="4"
                  >
                    <label for="">Отдел*</label>
                    <v-select
                      v-model="selectedChild"
                      solo
                      color="#5CB7B1"
                      item-color="#5CB7B1"
                      background-color="white"
                      item-text="divisionName"
                      item-value="id"
                      flat
                      class="mb-2"
                      hide-details="auto"
                      outlined
                      dense
                      placeholder="Не выбрано"
                      :items="btsChilds"
                      clearable
                      @change="loadBtsUsers"
                    />
                  </v-col>
                  <!-- <v-col
                    cols="12"
                    lg="4"
                  >
                    <label for="">Декларант</label>
                    <v-select
                      v-model="selectedUser"
                      solo
                      color="#5CB7B1"
                      item-color="#5CB7B1"
                      background-color="white"
                      item-text="firstName"
                      flat
                      class="mb-2"
                      hide-details="auto"
                      outlined
                      dense
                      placeholder="Любой свободный"
                      :items="btsUsers"
                      item-value="id"
                      clearable
                    >
                      <template
                        slot="selection"
                        slot-scope="data"
                      >
                        {{ data.item.lastName }} {{ data.item.firstName[0] }}. {{ data.item.middleName[0] }}.
                      </template>
                      <template
                        slot="item"
                        slot-scope="data"
                      >
                        {{ data.item.lastName }} {{ data.item.firstName[0] }}. {{ data.item.middleName[0] }}.
                      </template>
                    </v-select>
                  </v-col> -->
                </v-row>
              </v-col>
              <v-col
                cols="12"
                lg="5"
              >
                <v-alert
                  color="#F5F5F5"
                  elevation="2"
                  class="pt-6"
                >
                  <v-btn
                    fab
                    x-small
                    class="info-icon"
                  >
                    <v-icon>mdi-information-variant</v-icon>
                  </v-btn>
                  <div class="text-body-2 black--text d-flex align-start mb-2">
                    <v-icon
                      x-small
                      color="#000"
                    >
                      mdi-asterisk
                    </v-icon>
                    <span>Для максимальной скорости оформления оставьте значения "любой свободный".</span>
                  </div>
                  <div class="text-body-2 black--text d-flex align-start">
                    <v-icon
                      x-small
                      color="#000"
                    >
                      mdi-asterisk
                    </v-icon>
                    <span>Администратор отдела таможенного оформления оставляет за собой право переназначить ДТ другому исполнителю.</span>
                  </div>
                </v-alert>
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col
          cols="12"
          lg="12"
          class="text-end"
        >
          <v-btn
            dark
            color="#5CB7B1"
            large
            :loading="load"
            @click="sendRequest"
          >
            Отправить заявку
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import {DT, SD} from '@/http-routes'

export default {
  data() {
    return {
      acceptedFormats: '.png, .jpg, .jpeg, .pdf, .doc, .webp, .docx, .xml',
      load: false,
      userFIO: '',
      userEmail: '',
      userPhone: '',
      clientComment: '',
      deliveryTermsCode: null,
      deliveryTypeCode: null,
      deliveryPlace: '',
      dealCharacterCode: null,
      borderTransportTypeCode: null,
      prepayment: false,
      selectedCountry: null,
      selectedPackage: null,
      needOriginalBill:false,
      billAddress: "",
      // files
      commercialDocs:[],
      contract: [],
      contractAdditional: [],
      contractRegistration: [],
      paymentCondition: [],
      invoice: [],
      borderTransport: [],
      packageType: [],
      // files
      otherDocs: [
        {
          file: null,
          fileName: ''
        }
      ],
      selectedBranch: null,
      selectedChild: null,
      selectedUser: null,
      visibility:{ //  Отображение ссодержимого блока
        user:true,
        commercialDocs:true,
        originalBill:true,
        counteragent:false,
        contract:false,
        contractAdditional:false,
        contractRegistration:false,
        paymentCondition:false,
        invoice:false,
        delivery:false,
        transport:false,
        package:false,
        destination:false,
        deal:false,
        documents:false,
        performer:true,
      },
      type: 'ДТ',
      code: 'ЭК',
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
      btsDivisions: 'auth/getBtsDivisions',
      btsChilds: 'auth/getBtsChilds',
      btsUsers: 'auth/getBtsUsers',
      countries: "catalogs/getNsiCountries",
      deliveryTerms: "catalogs/getNsiDeliveryTerms",
      characters: "catalogs/getNsiDealCharactersDT",
      delivery: "catalogs/getNsiTypesOfDelivery",
      transport: "catalogs/getNsiTransportTypes",
      packages: "catalogs/getNsiPackageTypes",
      division: "auth/getSingleDivision"
    }),
    codes() {
      if (this.type === "ДТ") return ['ЭК']
      else return ['ЭК', 'ИМ']
    },
    isTypeSD() {
      return this.type === "СД"
    }
  },
  watch:{
    division:{
      handler(nv){
        const {fullAddress} = nv || {}
        this.billAddress = fullAddress || ""
      },
      deep:true
    }
  },
  beforeCreate(){
    // справочники
    this.$store.dispatch("catalogs/fetchAll");
  },
  created(){
    this.$store.dispatch("auth/loadBtsDivisions")
    this.$store.dispatch('auth/loadSingleDivisions', this.user.user.activeDivisionId)
    this.userFIO = `${this.user.user.lastName} ${this.user.user.firstName} ${this.user.user.middleName}`
    this.userEmail = this.user.user.email
    this.userPhone = this.user.user.phone

  },
  methods: {
    toggleVisibility(block){
      this.visibility[block] = !this.visibility[block]
    },
    sendRequest(){
      if(!this.selectedBranch || !this.selectedChild){
        return this.$snackbar({
          text: `Не выбран исполнитель`,
          color: "red",
          top:false
        });
      }
      if(!this.commercialDocs.length){
        return this.$snackbar({
          text: `Отсутствуют Коммерческие документы`,
          color: "red",
          timeout: "3000",
          top:false
        });
      }
      if(this.needOriginalBill && !this.billAddress.length){
        return this.$snackbar({
          text: `Введите адрес для отправки акта работ`,
          color: "red",
          timeout: "3000",
          top:false
        });
      }
      this.load = true
      let newOrder = {
        id: null,
        declarationId: null,
        direction: "ЭК",
        clientFio: this.userFIO,
        clientEmail: this.userEmail,
        clientPhone: this.userPhone,
        clientComment: this.clientComment,
        counterAgentId: null, // пока не готов
        deliveryTermsCode: this.deliveryTermsCode ? this.deliveryTermsCode.code : null,
        deliveryTypeCode: this.deliveryTypeCode ? this.deliveryTypeCode.code : null,
        deliveryPlace: this.deliveryPlace,
        borderTransportTypeCode: this.borderTransportTypeCode ? this.borderTransportTypeCode.code : null,
        packageTypeCode: this.selectedPackage ? this.selectedPackage.code : null,
        packageTypeName: this.selectedPackage ? this.selectedPackage.name : null,
        prepayment: this.prepayment,
        destinationCountryLetterCode: this.selectedCountry ? this.selectedCountry.letterCode : null,
        destinationCountryDigitCode: this.selectedCountry ? this.selectedCountry.digitCode : null,
        destinationCountryName: this.selectedCountry? this.selectedCountry.name : null,
        dealCharacterCode: this.dealCharacterCode ? this.dealCharacterCode.code : null,
        userId: this.selectedUser,
        divisionId: this.selectedChild,
        otherDocumentsName: '',
        clientDivisionId: this.user.user.activeDivisionId,
        billAddress: this.needOriginalBill ? this.billAddress : null
      }
      if (this.isTypeSD) newOrder.customsProcedure = this.code;

      var form = new FormData();
      form.append("order", JSON.stringify(newOrder));

      const names = []
      this.otherDocs.forEach((doc, idx) => {
        if(doc.file){
          doc.file.forEach((item, index) =>{
            const key = `other-doc-${idx}-${index}`
            form.append(key, item)
            names.push({key, name:doc.fileName})
          })
        }
      })

      if(this.commercialDocs.length){
        for(let i = 0; i < this.commercialDocs.length; i++){
          form.append("commercial", this.commercialDocs[i]);
        }
        names.push({key: 'commercial', name: 'Коммерческие документы' })
      }

      if(this.contract.length){
        for(let i = 0; i < this.contract.length; i++){
          form.append("contract", this.contract[i]);
        }
        names.push({key: 'contract', name: 'Договор' })
      }

      if(this.contractAdditional.length){
        for(let i = 0; i < this.contractAdditional.length; i++){
          form.append("contractAdditional", this.contractAdditional[i]);
        }
        names.push({key: 'contractAdditional', name: 'Приложения к договору' })
      }

      if(this.contractRegistration.length){
        for(let i = 0; i < this.contractRegistration.length; i++){
          form.append("contractRegistration", this.contractRegistration[i]);
        }
        names.push({key: 'contractRegistration', name: 'Регистрация сделки' })
      }

      if(this.paymentCondition.length){
        for(let i = 0; i < this.paymentCondition.length; i++){
          form.append("paymentCondition", this.paymentCondition[i]);
        }
        names.push({key: 'paymentCondition', name: 'Условия оплаты' })
      }

      if(this.invoice.length){
        for(let i = 0; i < this.invoice.length; i++){
          form.append("invoice", this.invoice[i]);
        }
        names.push({key: 'invoice', name: 'Счет-фактура' })
      }

      if(this.borderTransport.length){
        for(let i = 0; i < this.borderTransport.length; i++){
          form.append("borderTransport", this.borderTransport[i]);
        }
        names.push({key: 'borderTransport', name: 'Транспорт' })
      }

      if(this.packageType.length){
        for(let i = 0; i < this.packageType.length; i++){
          form.append("packageType", this.packageType[i]);
        }
        names.push({key: 'packageType', name: 'Упаковка' })
      }

      form.append('names',JSON.stringify(names))
      const urlPath = this.isTypeSD ? SD : DT;
      axios.post(`${urlPath}order/create`, form)
        .then(() =>{
          this.$router.replace('/journal')
        }).catch(() => {
          this.$snackbar({
            text: `Ошибка`,
            color: "red",
            timeout: "3000",
            top:false
          });

        }).finally(() => this.load = false)
    },
    loadBtsDepartments(){
      this.selectedChild = null
      this.selectedUser = null
      this.$store.dispatch("auth/loadBtsChilds", this.selectedBranch)
    },
    loadBtsUsers(){
      this.selectedUser = null
      this.$store.dispatch("auth/loadBtsUsers", this.selectedChild)
    },
    deleteDoc(i){
      this.otherDocs.splice(i, 1)
    },
    addDoc(){
      this.otherDocs.push({ file: null, fileName: ''})
    }
  }
}
</script>

<style scoped>
.info-icon{
    position: absolute;
    left: calc(50% - 15px);
    top: -15px;
}
</style>
